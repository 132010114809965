import wheel from "../../img/wheel-holderr.webp";
// import wheelLocale from "../../img/wheel-locale.png";
import logo from "../../img/logo-ny.svg";
// import jack1 from "../../img/jack.webp";
// import add1 from "../../img/add-1.webp";
// import add2 from "../../img/add-2.webp";
// import add4 from "../../img/add-4.webp";
import partners from "../../img/partners.png";
import partnersMob from "../../img/partners_mob.png";
import footerLogo from "../../img/footer-logo-2.png";

export const Wheel = ({ startWheel, location }) => {
  return (
    <body className="page">
      <div className="page__wrap">
        <a href="" className="logo page__logo">
          <img src={logo} className="logo__img" />
        </a>
        <div className="header-text">
          <h2>
            {location === "CH" || location === "DE" || location === "AT"
              ? "Drehen Sie das Rad und erhalten"
              : "SPIN THE WHEEL AND"}
          </h2>

          <h4>
            {location === "CH" || location === "DE" || location === "AT"
              ? "Sie Freispiele"
              : "GET FREE SPINS"}
          </h4>
        </div>

        <div className="game-layout">
          <img src="" className="game-person gonzo" alt="" />
          <div className="game-layout__body">
            <div className="wheel wheel_animated">
              <div className="wheel__spinner-holder">
                <img
                  src={
                    location === "CH" || location === "DE" || location === "AT"
                      ? wheel //change
                      : wheel
                  }
                  className="wheel__spinner wheel__spinner_animated"
                />

                <div className="wheel__mask"></div>
                <div className="wheel__button-holder wheel__button-holder_blinking">
                  <div className="wheel__button-pointer">
                    <button
                      className="circle-button wheel__button spin-trigger first_spin"
                      onClick={startWheel}
                    ></button>
                  </div>
                </div>
              </div>
              <div className="wheel__win-frame"></div>
            </div>
          </div>
          {/* <img src={jack1} className="game-person jack" alt="" />
          <img src={add1} class="game-person add-1" alt="" />
          <img src={add2} class="game-person add-2" alt="" />
          <img src={add4} class="game-person add-2" alt="" /> */}
        </div>

        <div class="page__footer">
          <div class="footer__container">
            <div class="footer__line display-none"></div>
            <div class="footer__partners">
              <img class="decs" src={partners} alt="partners" />
              <img class="mob" src={partnersMob} alt="partners" />
            </div>
            <div class="footer__line"></div>
            <div class="footer__info">
              <div class="footer__text">
                <p>
                  Copyright © 2023 Casinokakadu.com is operated by N1
                  Interactive Ltd, a company incorporated under the laws of
                  Malta with registration number C 81457 and registered address
                  at 206, Wisely House, Old Bakery Street, Valletta VLT1451,
                  Malta. Casinokakadu.com is licensed and regulated by the Malta
                  Gaming Authority under the licences: MGA/B2C/394/2017 (issued
                  on 01/08/2018). Gambling can be addictive. Play responsibly.
                  Casinokakadu.com only accepts customers over 18 years of age.
                </p>
              </div>
              <div class="footer__logo logo-two">
                <img class="dec" src={footerLogo} alt="partners" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};
