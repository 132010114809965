export const Errors = ({ errorPath }) => {
  return (
    <>
      {errorPath && (
        <div className="errors">
          {Object.values(errorPath).map((error) => (
            <div>{error}</div>
          ))}
        </div>
      )}
    </>
  );
};
