import { useEffect, useRef, useState } from "react";
import arrowBack from "../../img/arrow-back.png";
import { request } from "../../utils/http";
import { CustomSelect } from "./CustomSelect/CustomSelect";
import { Errors } from "../Errors/Errors";
import { countryCodes } from "../../utils/countrycodes";

const countries = [
  { name: "Argentina", value: "AR", dial_code: "+54" },
  { name: "Australia", value: "AU", dial_code: "+61" },
  { name: "Austria", value: "AT", dial_code: "+43" },
  { name: "Brazil", value: "BR", dial_code: "+55" },
  { name: "Canada", value: "CA", dial_code: "+1" },
  { name: "Chile", value: "CL", dial_code: "+56" },
  { name: "Cyprus", value: "CY", dial_code: "+357" },
  { name: "Denmark", value: "DK", dial_code: "+45" },
  { name: "Finland", value: "FI", dial_code: "+358" },
  { name: "Germany", value: "DE", dial_code: "+49" },
  { name: "Ireland", value: "EI", dial_code: "+353" },
  { name: "Italy", value: "IT", dial_code: "+39" },
  { name: "Japan", value: "JP", dial_code: "+81" },
  { name: "Kuwait", value: "KW", dial_code: "+965" },
  { name: "Lesotho", value: "LS", dial_code: "+266" },
  { name: "Luxembourg", value: "LU", dial_code: "+352" },
  { name: "Malta", value: "MT", dial_code: "+356" },
  { name: "Mexico", value: "MX", dial_code: "+52" },
  { name: "New Zealand", value: "NZ", dial_code: "+64" },
  { name: "Norway", value: "NO", dial_code: "+47" },
  { name: "Peru", value: "PE", dial_code: "+51" },
  { name: "Puerto Rico", value: "PR", dial_code: "+1939" },
  { name: "Saint Vincent and the Grenadines", value: "VC" },
  { name: "Mexico", value: "MX", dial_code: "+1784" },
  { name: "Slovenia", value: "SI", dial_code: "+386" },
  { name: "South Africa", value: "ZA", dial_code: "+27" },
  { name: "Switzerland", value: "CH", dial_code: "+41" },
  { name: "United Arab Emirates", value: "AE", dial_code: "+971" },
  { name: "Uruguay", value: "UY", dial_code: "+598" },
];

const currencies = [
  { name: "USD", value: "USD" },
  { name: "EUR", value: "EUR" },
  { name: "NOK", value: "NOK" },
  { name: "CAD", value: "CAD" },
  { name: "PLN", value: "PLN" },
  { name: "NZD", value: "NZD" },
  { name: "JPY", value: "JPY" },
  { name: "AUD", value: "AUD" },
  { name: "BRL", value: "BRL" },
  { name: "BTC", value: "BTC" },
  { name: "LTC", value: "LTC" },
  { name: "DOG", value: "DOG" },
  { name: "BCH", value: "BCH" },
];

export const SignUpModal = ({ location }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [day, setDay] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [city, setCity] = useState();
  const [address, setAddress] = useState();
  const [postalCode, setPostalCode] = useState();
  const [country, setCountry] = useState();
  const [currency, setCurrency] = useState();
  const [errors, setErrors] = useState();
  const [phoneCode, setPhoneCode] = useState();
  const monthRef = useRef();
  const yearRef = useRef();

  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const handleNextStep = () => {
    setStep((step) => step + 1);
  };
  const handlePrevStep = () => {
    setStep((step) => step - 1);
  };

  const signUp = async () => {
    setLoading(true);
    const user = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      country,
      currency,
      mobile_phone: phone,
      date_of_birth: `${year}-${month}-${day}`,
      city: city,
      address: address,
      postal_code: postalCode,
      age_acceptance: true,
      terms_acceptance: true,
    };

    const response = await request("users", "POST", 1, { user });

    if (response["errors"] === undefined) {
      localStorage.setItem("spinned", 1);
      window.location.href = `https://www.casinokakadu.com/`;
    }
    setErrors(response["errors"]);
    setLoading(false);
  };

  useEffect(() => {
    if (errors) {
      setStep((step) => {
        if (errors?.email || errors?.password) {
          return 1;
        }
        if (errors?.profile?.first_name || errors?.profile?.last_name) {
          return 2;
        }
        if (
          errors?.profile?.currency ||
          errors?.profile?.mobile_phone ||
          errors?.profile?.date_of_birth
        ) {
          return 3;
        }
        if (
          errors?.profile?.city ||
          errors?.profile?.address ||
          errors?.profile?.postalCode
        ) {
          return 4;
        }
        return step;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (day?.length === 2) {
      monthRef.current.focus();
    }
  }, [day?.length]);
  useEffect(() => {
    if (month?.length === 2) {
      yearRef.current.focus();
    }
  }, [month?.length]);

  useEffect(() => {
    if (location) {
      setCountry(location?.country_code);
      setCurrency(location?.default_currency);
    }
  }, [location]);

  useEffect(() => {
    if (countryCodes[country]) {
      setPhoneCode(countryCodes[country]?.dial_code);
    }
  }, [country]);

  return (
    <div className="fewmodal" id="modal-content">
      <div
        className="fewmodal__body"
        id="exampleModalToggle-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalToggleLabel-1"
      >
        <div className="fewmodal__content">
          <div className="popup-wrapper">
            <div className="popup">
              <h2 className="popup__titlle">
                {country === "CH" || country === "DE" || country === "AT"
                  ? "Gratulation!"
                  : "Congratilations!"}
              </h2>
              <h3>
                {country === "CH" || country === "DE" || country === "AT"
                  ? "sie haben einen Bonus gewonnen"
                  : "you have won a bonus"}
              </h3>
              <p>
                {country === "CH" || country === "DE" || country === "AT"
                  ? "Bekommen Sie "
                  : "Get "}
                <span>100%</span>{" "}
                {country === "CH" || country === "DE" || country === "AT"
                  ? "auf Ihre erste"
                  : "to your first"}{" "}
                {country !== "CH" && <br />}
                {country === "CH" || country === "DE" || country === "AT"
                  ? "Einzahlung"
                  : "deposit"}
                <span>
                  {country === "CH" || country === "DE" || country === "AT"
                    ? " + 100 Freispiele"
                    : " + 100 free spins!"}
                </span>
              </p>
              {step === 1 && (
                <div className="form">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="E-mail"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Errors errorPath={errors?.email} />

                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder={
                      country === "CH" || country === "DE" || country === "AT"
                        ? "Passwort"
                        : "Password"
                    }
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Errors errorPath={errors?.password} />
                </div>
              )}
              {step === 2 && (
                <div className="form">
                  <input
                    type="text"
                    id="first-name"
                    name="text"
                    placeholder={
                      country === "CH" || country === "DE" || country === "AT"
                        ? "Vorname"
                        : "First name"
                    }
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <Errors errorPath={errors?.profile?.first_name} />

                  <input
                    type="text"
                    id="last-name"
                    name="last-name"
                    placeholder={
                      country === "CH" || country === "DE" || country === "AT"
                        ? "Nachname"
                        : "Last name"
                    }
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <Errors errorPath={errors?.profile?.last_name} />
                </div>
              )}
              {step === 3 && (
                <div className="form">
                  <CustomSelect
                    item={country}
                    data={countries}
                    bool={setIsCountryOpen}
                    value={isCountryOpen}
                    setter={setCountry}
                  />

                  <Errors errorPath={errors?.profile?.country} />
                  <CustomSelect
                    item={currency}
                    data={currencies}
                    bool={setIsCurrencyOpen}
                    value={isCurrencyOpen}
                    setter={setCurrency}
                  />
                  <Errors errorPath={errors?.profile?.currency} />

                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    placeholder="Number"
                    required
                    value={phone || phoneCode || ""}
                    onChange={(e) => setPhone(e.target.value)}
                  />

                  <Errors errorPath={errors?.profile?.mobile_phone} />

                  <div className="date">
                    <input
                      type="text"
                      placeholder={
                        country === "CH" || country === "DE" || country === "AT"
                          ? "Tag"
                          : "Day"
                      }
                      id="Day"
                      value={day}
                      onChange={(e) => setDay(e.target.value)}
                    />
                    <input
                      ref={monthRef}
                      type="text"
                      placeholder={
                        country === "CH" || country === "DE" || country === "AT"
                          ? "Monat"
                          : "Month"
                      }
                      id="Month"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    />
                    <input
                      ref={yearRef}
                      type="text"
                      placeholder={
                        country === "CH" || country === "DE" || country === "AT"
                          ? "Jahr"
                          : "Year"
                      }
                      id="Year"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    />
                  </div>
                  <Errors errorPath={errors?.profile?.date_of_birth} />
                </div>
              )}
              {step === 4 && (
                <div className="form">
                  <input
                    type="text"
                    name="City"
                    placeholder={
                      country === "CH" || country === "DE" || country === "AT"
                        ? "Stadt"
                        : "City"
                    }
                    required
                    id="City"
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <Errors errorPath={errors?.profile?.city} />

                  <input
                    type="text"
                    name="Address"
                    id="Address"
                    placeholder={
                      country === "CH" || country === "DE" || country === "AT"
                        ? "Adresse"
                        : "Address"
                    }
                    required
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <Errors errorPath={errors?.profile?.address} />

                  <input
                    type="text"
                    id="postal-code"
                    name="postal-code"
                    placeholder={
                      country === "CH" || country === "DE" || country === "AT"
                        ? "Postleitzahl"
                        : "Postal code"
                    }
                    required
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                  <Errors errorPath={errors?.profile?.postal_code} />
                </div>
              )}
              <div className="flex">
                {step !== 1 && (
                  <button
                    className="btn-arrow popup__win-btn"
                    data-bs-target="#exampleModalToggle-3"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                  >
                    <img src={arrowBack} alt="arrow" onClick={handlePrevStep} />
                  </button>
                )}
                <button
                  className="btn btn-primary popup__win-btn"
                  onClick={step === 4 ? signUp : handleNextStep}
                  disabled={isLoading}
                >
                  {step === 4
                    ? country === "CH" || country === "DE" || country === "AT"
                      ? "anmelden"
                      : "Sign Up"
                    : country === "CH" || country === "DE" || country === "AT"
                    ? "nächster Schritt"
                    : "Next Step"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
